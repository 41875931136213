.privacy-container {
  background-color: #282c34;
  /* min-height: 100vh; */
  color: white;
}

@media only screen and (max-width: 768px) {
  .privacy-container {
    padding: 2rem;
    background-color: #282c34;
  }

  .privacy-policy {
    width: 100%;
  }
}

@media only screen and (min-width: 980px) {
  .privacy-policy {
    padding-top: 5rem;
    width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
}

.privacy-container a, .privacy-container a:visited {
  color: white;
  text-decoration: none;
}

.privacy-policy h2 {
  font-size: 32px;
}

.privacy-policy p {
  font-size: 16px;
  line-height: 32px;
}

.privacy-section p a {
  color: #F15A50!important;
  text-decoration: none;
}