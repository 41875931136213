
.header {
  text-align: center;
  margin: 0;
  padding: 0;
}

.header li {
  display: inline;
  font-size: 14px;
}

.header li a {
  color: white;
  text-decoration: none;
}

.header li a:visited {
  color: white;
  text-decoration: none;
}